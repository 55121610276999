<template>
	<div>
		<h2>导出记录</h2>
		<el-button @click="getRecordExportHistory" size="small" icon="el-icon-refresh-left">刷新</el-button>
		<el-table :data="tableData" v-loading="loading" border stripe :header-cell-style="{background:'#E4E7ED',color: '#606266'}"
			size="small" max-height="500px" class="margin-t-20" style="width: 100%;">
			<el-table-column label="#" type="index" width="60px"></el-table-column>
			<el-table-column label="导出时间" prop="created_at"></el-table-column>
			<el-table-column label="操作人" prop="operator.name"></el-table-column>
			<el-table-column label="文件名" v-slot="{ row }">
				{{ getFileName(row.file) }}
			</el-table-column>
			<el-table-column label="操作" v-slot="{ row }">
				<el-link :href="row.file" target="_blank" type="primary">下载</el-link>
			</el-table-column>
		</el-table>
		<div class="margin-t-10 flex">
			<el-pagination background layout="prev, pager, next, total, jumper" :total="total" :current-page.sync="page"
				:page-size="pageSize" @current-change="getRecordExportHistory">
			</el-pagination>
			<el-button size="small">确定</el-button>
		</div>
	</div>
</template>

<script>
	import {
		getRecordExportHistoryAPI
	} from '@/api/user-record/record.js'

	export default {
		name: 'UserRecordExportHistory',
		data() {
			return {
				page: 1,
				tableData: [],
				pageSize: 0,
				total: 0,
				loading: false,
			}
		},
		created() {
			this.getRecordExportHistory()
		},
		methods: {
			getFileName(fileUrl) {
				const splitArr = fileUrl.split('/')
				return splitArr[splitArr.length - 1]
			},
			getRecordExportHistory() {
				this.loading = true

				getRecordExportHistoryAPI({
					page: this.page,
					type: 201
				}).then(res => {
					this.tableData = res.data
					this.total = res.total
					this.pageSize = res.per_page
					this.loading = false
				})
			}
		}
	}
</script>

<style>
</style>